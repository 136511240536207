.expb {
    display: grid;
    grid-template-columns: auto auto;
    flex-wrap: wrap;
    justify-content: center;
    gap: 2% 6%;
    padding-bottom: 0%;
}

.border {
    opacity: 0;
}


ul {
    margin-left: 2em;
}

.exps {
    height: 40em;
    width: 30em;
    box-sizing: border-box;
    padding: 10%;
    transition: transform 0.3s;
    border-radius: 25px;
}

.exps > * {
    padding: 4%;
}

.slidein {
    animation: slidein 0.7s forwards;
}

@keyframes slidein {
    from {
        transform: translateX(100%);
    } to {
        opacity: 1;
        transform: translateX(0%);
    }
}

@media (max-width:1024px) {
    .expb {
        grid-template-columns: auto;
    }
    .exps {
        width: 40em;
        padding-inline: 5%;
        margin-right: 10%;
    }
}

@media (max-width:767px) {
    .exps {
        width: 30em;
    }
}

@media (max-width:539px) {
    .exps {
        width: 20em;
        height: 45em;
    }
}


:root {
    --bg-color: #262626;
    --links_p: white;
    --comp-bg: #525252;
    --prime: #CC2936;
    --accent: #FFDF00;
    --border: #E47780;
    --tran-bg: rgba(38, 38, 38, 0.8);
    --hover-bg: rgb(255, 223, 0, 0.5);
    --linkimg: "./images/linkdin.svg";
    --gitimg: "./images/git.svg";
}

.light {
    --bg-color: white;
    --links_p: black;
    --comp-bg: #d1d1d1;
    --prime: #29CCBF;
    --accent: #016d62;
    --border: #77e4db;
    --tran-bg: rgba(209, 209, 209, 0.8);
    --hover-bg: rgb(1, 109, 98, 0.5);
    --linkimg: url("./images/linkdinlight.svg");
    --gitimg: url("./images/gitlight.svg");
}

.dark {
    --bg-color: #262626;
    --links_p: white;
    --comp-bg: #525252;
    --prime: #CC2936;
    --accent: #FFDF00;
    --border: #E47780;
    --tran-bg: rgba(38, 38, 38, 0.8);
    --hover-bg: rgb(255, 223, 0, 0.5);
    --linkimg: url("./images/linkdin.svg");
    --gitimg: url("./images/git.svg");
}

*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    background-color: var(--bg-color);
    color: aliceblue;  
    scroll-behavior: smooth;
}

h1 {
    font-size: xx-large;
    margin: 2%;
}

p {
    font-weight:300;
    font-size: larger;
}

body {
    color: white;
    overflow: hidden;
    overflow-y: scroll;    
    font-family: 'Poppins', 'sans-serif';
    display: flex;
    flex-direction: column;
    text-decoration: none; 
    justify-content: center;
    align-items: center;
}

.decor {
    display: flex;
    flex-direction: row;
}

.background, .background > *, .background > * > *, .background > * > * > * {
    background-color: var(--comp-bg);
}

button {
    background-color: transparent;
    border: none;
    font-family: 'Poppins', 'sans-serif';
    margin: 2%;
    padding: 5%;
}

hr {
    border: solid 1px var(--prime);
    width: 300px;
}

.pages {
    margin-right: 1em;
}

.pages:hover {
    color: var(--accent);
}

p , h1, h2 , h3, .pages, li {
    color: var(--links_p);
}

#name {
    color:var(--prime);
    font-size: x-large;
    font-family: "Silkscreen", sans serif;
}

.typer {
    font-size: xxx-large;
    animation: typing 3s steps(20, end), blink-caret 1s step-end infinite;
    overflow: hidden;
    background: linear-gradient(to right, var(--prime), var(--links_p));
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
    border-right: .15em solid var(--prime);
    width: fit-content;
    display: inline;
    white-space: nowrap;
    background-color: var(--prime);
}

.skills > *:hover > *{
    background-color: rgb(255, 223, 0, 0);
    transform: scale(1.1);
}

.skills > *:hover{
    transform: rotateY(720deg);
    background-color: var(--hover-bg);
    transform: scale(1.1);
}

#git {
    background-image: var(--gitimg);
}

#link {
    background-image: var(--linkimg);
}

#git, #link {
    width: 40px; 
    height: 40px;
    background-size: contain;
    background-repeat: no-repeat;
    transition: transform 0.4s ease;
}

#git:hover, #link:hover {
    transform: scale(1.1);
}

.repos {
    width: 30px; 
    height: 30px;
    background-size: contain;
    background-repeat: no-repeat;
    transition: transform 0.4s ease;
    background-image: var(--gitimg);
}

.repos:hover {
    transform: scale(1.1);
}


@keyframes typing {
    from {
        width:0;
    } 
    to {
        width: 100%;
    }
}

@keyframes blink-caret {
    0%{
        border-color: transparent;
    } 50% { 
        border-color: var(--prime);
    } 100% {
        border-color: transparent;
    }
}

.nav {
    border: var(--border);
    border-style: solid;
    background-color: var(--tran-bg);
    backdrop-filter: blur(6px);
}

.banner_col {
    border-radius: 25px;
    padding-bottom: 10%;
    padding-top:5%;
    margin: 5% auto;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    width: 90%;
}

.ice{
    margin-top: 10%;
}

.accents {
    color: var(--accent);
}

.footer {
    color: var(--accent);
}

.footlinks {
    margin-left: auto;
    display: flex;
}

@media (max-width:912px) {
    hr {
        width: 100px;
    }
    .divice {
        display: none;
    }
    #git {
        width: 40px;
    }
    #link {
        width: 40px;
    }
}

@media (max-width: 767px) {
    h3 {
        font-size: medium ;
    }
    .typer {
        font-size: x-large;
    }
    h1 {
        font-size: x-large;
    }
    p {
        font-size: medium;
    }
    #name {
        display: none;
    }
    
}

@media (max-width:540px) {
    hr {
        width: 80px;
    }
    .banner_col {
        padding-bottom: 15%;
    }
}

@media (max-width: 539px) {
    .banner_col {
        padding-bottom: 20%;
    }
}





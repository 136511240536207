.box {
    display: flex;
    flex-direction: column;
    padding-top: 2%;
}


.projbox {
    position: relative;
    perspective: 1000px;
    margin: 350px auto;
    width: 100%;
}

.border {
    opacity: 0;
}

.projs {
    display: flex;
    justify-content: center;
    transform: translateZ(-516px);
    transform-style: preserve-3d;
    position: absolute;
    width: 40%;
    z-index: 5;
    transition: transform 0.8s ease-in-out;

}

.projs > * {
    position: absolute;
    text-align: left;
    display: flex;
    flex-direction: column;
    border-radius: 25px;
    margin: 2%; 
    width: 100%;
    border-radius: 25px;
    padding-inline: 5%;
    padding-top: 2%;
}

#prev {
    position: absolute;
    left: 10em; 
    z-index: 10;
    padding: 0;
    cursor: pointer;
}

#next {
    position: absolute;
    right: 8em; 
    z-index: 200;
    padding: 0;
    cursor: pointer;
}

.images {
    width: 100%;
    height: 300px;
    display: flex;
    justify-content: center;
}

.images > img {
    border-radius: 25px;
    width:100%;
    height: 275px;
    object-fit:cover;
    object-position:50% 50%;
}

.descrip {
    height: 550px;
    padding: 10%;
    padding-top: 5%;
    padding-inline: 0;
}

.descrip > * {
    transition: 1s opacity;
}

.descrip > p {
    padding: 2%
}

.slidein {
    animation: slidein 0.7s forwards;
}

.stack > img {
    transition: transform 0.2s ease-in-out;
}

.stack > img:hover {
    transform: scale(1.1);
}

@keyframes slidein {
    from {
        transform: translateX(-100%);
    } to {
        opacity: 1;
        transform: translateX(0%);
    }
}


.carousel_cell:nth-child(1) { 
    transform: rotateY(0deg) translateZ(350px); 
}
.carousel_cell:nth-child(2) { 
    transform: rotateY(72deg) translateZ(350px); 
}
.carousel_cell:nth-child(3) { 
    transform: rotateY(144deg) translateZ(350px); 
}
.carousel_cell:nth-child(4) { 
    transform: rotateY(216deg) translateZ(350px); 
}
.carousel_cell:nth-child(5) { 
    transform: rotateY(288deg) translateZ(350px); 
}

@media (min-width:1300px) {
    .projbox {
        margin: 450px auto;
    }
    .images {
        height: 25em;
    }
    .images > img {
        height: 20em;
    }
    .carousel_cell:nth-child(1) { 
        transform: rotateY(0deg) translateZ(500px); 
    }
    .carousel_cell:nth-child(2) { 
        transform: rotateY(72deg) translateZ(500px); 
    }
    .carousel_cell:nth-child(3) { 
        transform: rotateY(144deg) translateZ(500px); 
    }
    .carousel_cell:nth-child(4) { 
        transform: rotateY(216deg) translateZ(500px); 
    }
    .carousel_cell:nth-child(5) { 
        transform: rotateY(288deg) translateZ(500px); 
    }
}

@media (min-width:1600px) {
    .images {
        height: 30em;
    }
    .images > img {
        height: 25em;
    }
}

@media (min-width:1900px) {
    .projbox {
        margin: 500px auto;
    }
    .carousel_cell:nth-child(1) { 
        transform: rotateY(0deg) translateZ(600px); 
    }
    .carousel_cell:nth-child(2) { 
        transform: rotateY(72deg) translateZ(600px); 
    }
    .carousel_cell:nth-child(3) { 
        transform: rotateY(144deg) translateZ(600px); 
    }
    .carousel_cell:nth-child(4) { 
        transform: rotateY(216deg) translateZ(600px); 
    }
    .carousel_cell:nth-child(5) { 
        transform: rotateY(288deg) translateZ(600px); 
    }
}

@media (max-width:1100px) {
    #prev {
        left: 5em;
    }
    #next {
        right:5em;
    }
    .projs {
        width: 50%;
    }
}

@media (max-width:911px) {
    .projbox {
        margin-bottom: 65%;
    }
    #prev {
        left: 3em;
    }
    #next {
        right: 2em;
    }
    .projs {
        width: 50%;
    }
}

@media (max-width:767px) {
    .images {
        height: 400px;
    }
    .images > img {
        height: 375px;
    }
    #prev, #next {
        display: none;
    }
    .carousel_cell:nth-child(1) { 
        transform: none;
    }
    .carousel_cell:nth-child(2) { 
        transform: none;
    }
    .carousel_cell:nth-child(3) { 
        transform: none;
    }
    .carousel_cell:nth-child(4) { 
        transform: none;
    }
    .carousel_cell:nth-child(5) { 
        transform: none;
    }

    .projs {
        display: flex;
        flex-direction: column;
        position: relative;
        transform-style: none;
        width: 90%;
        transform: none;
    }

    .projbox {
        margin: 0% auto;
        perspective: none;
    }

    .projs > * {
        position: relative;
    }
}






#profile {
    width: 50%;
    border-radius: 10%;
}

#decor > h1 {
    white-space: nowrap;
}

#border {
    opacity: 0;
}

.slidein {
    animation: slidein 0.7s forwards;
}

@keyframes slidein {
    from {
        transform: translateX(100%);
    } to {
        opacity: 1;
        transform: translateX(0%);
    }
}

#abu {
    padding: 5%;
    display: flex;
    flex-direction: column;
}


#abu > p {
    padding: 5%;
    padding-left: 5%;
    padding-bottom: 0%;
}



#banner {
    padding-top: 30%;
    padding-bottom: 20%;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: fit-content;
    justify-content: center;
    text-align: center;
}

#intro {
    display: flex;
    flex-direction: row;
    width: fit-content;
}

#slotanim {
    height: 80px;
}

#hlinks {
    display: none;
}

@media (max-width: 767px) {
    #banner {
        padding-top: 20%;
        padding-bottom: 20%;
    }
}

@media (max-width:911px) {
    #hlinks {
        width: 100%;
        display: flex;
        padding-top: 2%;
        padding-left: auto;
    }
    #hlinks > * {
        margin: 4%;
        width: 5%;
    }
}





* {
    text-decoration: none; 
    justify-content: center;
    align-items: center;
}

.nav {
    border-radius: 25px;
    position: fixed;
    left:0;
    right:0;
    width: 91%;
    display: flex;
    z-index: 1;
    align-items: center;
    justify-content: center;
    margin: 3%;
    padding: 1%;
    animation: open 0.8s forwards;
}

.nav.close {
    animation: close 0.8s forwards;
}

#name {
    color:#DA4450;
}

@keyframes open {
    from {
        transform: translateY(-1000px);
    } to {
        transform: translateY(0);
    }
}

@keyframes close {
    from {
        transform: translateY(0);
    } to {
        transform: translateY(-1000px);
    }
}

.nav > *, .nav > * > * {
    background-color: transparent;
}

.menu {
    display:flex;
    margin: auto;
    margin-right: 5%;
}

#snam {
    display: none;
}

@media (max-width: 910px) {
    #name {
        display: none;
    }

    .nav {
        width: 98.9%;
        margin: 0;
        padding: 0;
        bottom:0;
        animation: none;
        font-size: smaller;
    }

    .menu {
        margin: auto;
        margin-top: 5%;
        margin-bottom: 5%;
    }

    .nav.close {
        animation: none;
    }
}
#banner {
    width: 100%;
    height: 10vh;
    display: flex;
    justify-content: flex-start;
    padding-inline: 10%;
}

#banner > * {
    padding-right: 2%;
}

#banner > a {
    padding-right: 0;
}

@media (width:912px) {
    #banner {
        padding-inline: 20%;
    }
}

@media (max-width:911px) {
    #banner {
        display: none;
    }
}


#selection {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.border {
    opacity: 0;
}

img {
    width: 2em;
}

#pinia {
    width: 1.4em;
}

#options {
    display: flex;
    flex-direction: row;
    text-align: left;
    align-items: center;
    justify-content: center;
}

#options > * {
    white-space: nowrap;
    cursor: pointer;
}

.grid {
    display: grid;
    grid-template-columns: auto auto auto;
    padding-bottom: 10%;
    height: 10em;
    gap: 10% 10%;
}

.grid > * {
    display: flex;
    justify-content: flex-start;
    border: solid 1px white;
    border-radius: 3%;
    padding-inline: 20%;
    margin: 2%;
    width: 200px;
    transition: transform 0.3s ease;
    cursor: pointer;
}


.grid > * > * {
    margin: 8%;
    margin-left:0;
    white-space: nowrap;
    font-size: small;
}

.tools {
    display: none;
}

.tech {
    display: none;
}

.slidein {
    animation: slidein 0.7s forwards;
}

@keyframes slidein {
    from {
        transform: translateX(-100%);
    } to {
        opacity: 1;
        transform: translateX(0%);
    }
}

@media (max-width:912px) {
    .grid {
        grid-template-columns: auto auto;
        padding-bottom: 30%;
    }
}

@media (max-width:539px) {
    .grid {
        grid-template-columns: auto;
        padding-top: 10%;
        padding-bottom: 130%;
    }
    #options {
        padding-inline: 2%;
    }
    #options > * {
        padding-inline: 0;
        margin-inline: 5px;
    }
}
